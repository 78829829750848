<section class="my-5 py-5 py-lg-6 background-pink">
    <div class="container">
        <div class="row" appAos>
            <div class="col text-center">
                <div [innerHTML]="body?.primary?.heading | renderHtml : headingSerializer"></div>
                <div [innerHTML]="body?.primary?.title | renderHtml : htmlSerializer"></div>
            </div>
        </div>
        <div class="row gy-5 gx-md-5 my-4 align-items-end">
            @for (c of body?.items; track c; let idx = $index) {
            <div
                class="col-lg-6 col-xl-4"
                appAos
                [ngClass]="
                    idx === 1
                        ? 'highlight-card order-lg-0 order-xl-1 offset-lg-3 offset-xl-0'
                        : 'order-lg-' + (idx + 1) + ' order-xl-' + idx
                "
            >
                <div class="card background-white d-flex flex-column justify-content-between">
                    <div class="px-3 px-lg-5 pb-5 pt-4">
                        <div>
                            <div class="d-flex justify-content-center">
                                <div class="lotti-wrapper lottie mb-4">
                                    @if (isBrowser) {
                                    <ng-lottie [options]="{ path: c.lottie_file?.url }"></ng-lottie>
                                    }
                                </div>
                            </div>
                            @if (c?.badge) {
                            <div class="d-flex justify-content-center my-4">
                                <div class="badge-outline">{{ c?.badge }}</div>
                            </div>
                            }
                            <div
                                class="text-center"
                                [innerHTML]="c?.card_title | renderHtml : (idx === 1 ? cardHighlightTitleSerializer : cardTitleSerializer)"
                            ></div>
                            <div
                                class="text-center d-flex flex-column align-items-center"
                                [innerHTML]="c?.card_text | renderHtml : cardTextSerializer"
                            ></div>
                        </div>
                        <div>
                            @if (c?.card_secondary_button_title) {
                            <app-link cssClass="btn btn-black-border w-100 mt-4" [link]="c?.card_secondary_button_link"
                                >{{ c?.card_secondary_button_title }}
                            </app-link>
                            }
                            <app-link cssClass="btn btn-black w-100 mt-4" [link]="c?.card_button_link" [action]="c?.card_button_action"
                                >{{ c?.card_button_title }}
                            </app-link>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
</section>
