import { isPlatformBrowser, NgClass } from '@angular/common';
import { Component, inject, Input, PLATFORM_ID } from '@angular/core';
import { ContentRelationshipField, KeyTextField, LinkToMediaField, RichTextField, SelectField, Slice } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { LottieComponent } from 'ngx-lottie';
import { AosDirective } from '../../aos/aos.directive';
import { LinkComponent } from '../../link/link.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-three-columns-icon-text-slice',
    templateUrl: './three-columns-icon-text-slice.component.html',
    styleUrls: ['./three-columns-icon-text-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, NgClass, LottieComponent, LinkComponent, RenderHtmlPipe]
})
export class ThreeColumnsIconTextSliceComponent {
    @Input() body?: ThreeColumnsIconTextSlice;
    private platformId = inject<Object>(PLATFORM_ID);
    isBrowser = isPlatformBrowser(this.platformId);

    headingSerializer: HTMLMapSerializer = {
        heading2: ({ children }) => '<h2 class="heading color-white">' + children + '</h2>',
        paragraph: ({ children }) => '<p class="heading color-white">' + children + '</p>'
    };

    htmlSerializer: HTMLMapSerializer = {
        heading2: ({ children }) => '<h2 class="text-size-xl color-white">' + children + '</h2>',
        paragraph: ({ children }) => '<p class="text-size-xl color-white">' + children + '</p>'
    };

    cardTitleSerializer: HTMLMapSerializer = {
        heading3: ({ children }) => '<h2 class="text-size-m">' + children + '</h2>',
        paragraph: ({ children }) => '<p class="text-size-m">' + children + '</p>'
    };

    cardHighlightTitleSerializer: HTMLMapSerializer = {
        heading3: ({ children }) => '<h2 class="text-size-l">' + children + '</h2>',
        paragraph: ({ children }) => '<p class="text-size-l">' + children + '</p>'
    };

    cardTextSerializer: HTMLMapSerializer = {
        list: ({ children }) => '<ul class="list-unstyled text-start">' + children + '</ul>',
        listItem: ({ children }) => '<li class="mt-2 fw-semibold list-item-plus pe-0">' + children + '</li>'
    };
}

export type ThreeColumnsIconTextSlice = Slice<
    'three_columns_icon_text',
    {
        heading: RichTextField;
        title: RichTextField;
    },
    {
        card_title: RichTextField;
        card_text: RichTextField;
        card_button_title: KeyTextField;
        card_button_link: ContentRelationshipField;
        card_secondary_button_title: KeyTextField;
        card_secondary_button_link: ContentRelationshipField;
        card_button_action: SelectField;
        lottie_file: LinkToMediaField;
        badge: KeyTextField;
        upper_badge: KeyTextField;
    }
>;
