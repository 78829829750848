import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { KeyTextField, LinkField, PrismicDocument, RichTextField, SelectField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { RatingModule } from 'ngx-bootstrap/rating';
import { StandardPage } from '../../../standard/standard.service';
import { LinkComponent } from '../../link/link.component';
import { Rating } from '../ratings-slice/ratings-slice.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-minimal-header',
    templateUrl: './minimal-header.component.html',
    styleUrls: ['./minimal-header.component.scss'],
    standalone: true,
    imports: [RatingModule, FormsModule, LinkComponent, DecimalPipe, RenderHtmlPipe]
})
export class MinimalHeaderComponent implements OnInit {
    @Input() body!: MinimalHeaderSlice;
    @Input() standardPage!: PrismicDocument<StandardPage>;

    stars?: number;
    numberOfRatings?: number;

    constructor() {}

    ngOnInit(): void {
        if (this.body.items.find((action) => action.button_action === 'Bewerten')) {
            this.setupRatings();
        }
    }

    htmlSerializer: HTMLMapSerializer = {
        heading1: ({ children }) => '<h1 class="text-size-m color-white">' + children + '</h1>',
        heading2: ({ children }) => '<h2 class="text-size-xl color-white my-lg-6">' + children + '</h2>'
    };

    private setupRatings() {
        const ratingSlice = this.standardPage.data.body?.find((slices) => slices.slice_type === 'ratings');

        if (!ratingSlice) {
            return;
        }

        const ratings = ratingSlice?.items as Rating[];
        this.numberOfRatings = ratingSlice?.items.length;

        let starsAggregated = 0;
        for (let rating of ratings) {
            starsAggregated += Number(rating.stars);
        }

        this.stars = starsAggregated / this.numberOfRatings;
    }
}

export type MinimalHeaderSlice = Slice<
    'header',
    {
        headline: TitleField;
        title: KeyTextField;
        description: RichTextField;
        button_title: KeyTextField;
        button_link: LinkField;
        button_action: SelectField;
    },
    MinimalHeaderAction
>;

export type MinimalHeaderAction = {
    button_title: KeyTextField;
    button_link: LinkField;
    button_anker: KeyTextField;
    button_action: SelectField;
    button_style: SelectField;
};
