<div class="my-4 my-lg-6">
    <div class="container">
        <div class="row" appAos>
            <div class="col-12">
                <div class="background-pink py-5 px-3 px-lg-5">
                    <div class="col-12">
                        <div [innerHTML]="body.primary.title | renderHtml : htmlSerializer"></div>
                    </div>
                    <div class="col-12">
                        @for (comparison of body.items; track comparison) {
                        <div class="row my-5">
                            <div class="col-9 col-lg-10 pe-4">
                                <div class="progress-bar mt-2 mb-3" [style.width]="comparison.value_actual + '%'"></div>
                                <span class="color-white pt-3"
                                    ><b>{{ comparison.caption }}</b></span
                                >
                            </div>
                            <div class="col-3 col-lg-2">
                                <span class="color-white text-size-l">{{ comparison.value_displayed }}</span>
                            </div>
                        </div>
                        }
                    </div>
                    <div class="col-12">
                        <div [innerHTML]="body.primary.note | renderHtml : htmlSerializer"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
