import { Component } from '@angular/core';
import { AosDirective } from '../../aos/aos.directive';

@Component({
    selector: 'app-curly-arrow-slice',
    templateUrl: './curly-arrow-slice.component.html',
    styleUrls: ['./curly-arrow-slice.component.scss'],
    standalone: true,
    imports: [AosDirective]
})
export class CurlyArrowSliceComponent {}
