<div class="container py-5 py-lg-6">
    <div class="row">
        <div class="col-lg-4 mt-lg-6" [class]="body.primary.list_position_right ? 'pe-lg-6' : 'order-lg-1 ps-lg-6'" appAos>
            <div [innerHTML]="body.primary.description | renderHtml"></div>
        </div>
        <div class="col-lg-8 mt-lg-5" [class.order-lg-0]="body.primary.list_position_right">
            <ul class="list-unstyled">
                @for (listItem of body.items; track listItem) {
                <li class="py-5" appAos>
                    <div class="row">
                        <div class="col-lg-5 col-xl-4">
                            <div [innerHTML]="listItem.title | renderHtml"></div>
                        </div>
                        <div class="col-lg-7 col-xl-8">
                            <div [innerHTML]="listItem.description | renderHtml : htmlSerializer"></div>
                        </div>
                    </div>
                </li>
                }
            </ul>
        </div>
    </div>
</div>
