import { Component, Input, OnInit } from '@angular/core';
import { KeyTextField, NumberField, RichTextField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { AosDirective } from '../../aos/aos.directive';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-comparison-slice',
    templateUrl: './comparison-slice.component.html',
    styleUrls: ['./comparison-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, RenderHtmlPipe]
})
export class ComparisonSliceComponent implements OnInit {
    @Input() body!: ComparisonSlice;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        heading2: ({ children }) => '<h2 class="color-white">' + children + '</h2>',
        paragraph: ({ children }) => '<p class="text-size-xs">' + children + '</p>'
    };
}

export type ComparisonSlice = Slice<
    'comparison',
    {
        title: TitleField;
        note: RichTextField;
    },
    Comparison
>;

export type Comparison = {
    value_actual: NumberField;
    value_displayed: KeyTextField;
    caption: KeyTextField;
};
