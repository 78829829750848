import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StandardPage, StandardService } from './standard.service';

export const standardResolver: ResolveFn<Observable<PrismicDocument<StandardPage>>> = (route) => {
    const locale = route.data['locale'];
    const standardService = inject(StandardService);
    const router = inject(Router);

    return standardService.getStandardPage(route.paramMap.get('uid') || route.data['uid'], locale).pipe(
        catchError(() => {
            router.navigate(['/seite-nicht-gefunden']);
            return EMPTY;
        })
    );
};
