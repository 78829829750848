<div class="container">
    <div class="row">
        <div class="col-12">
            <h3 class="color-black">Web-Analyse mit etracker</h3>
            <p>
                Der Anbieter dieser Website nutzt Dienste der etracker GmbH aus Hamburg, Deutschland (<a href="https://www.etracker.com"
                    >www.etracker.com</a
                >) zur Analyse von Nutzungsdaten. Wir verwenden standardmäßig keine Cookies für die Web-Analyse. Soweit wir Analyse- und
                Optimierungs-Cookies einsetzen, holen wir Ihre explizite Einwilligung gesondert im Vorfeld ein. Ist das der Fall und Sie
                stimmen zu, werden Cookies eingesetzt, die eine statistische Reichweiten-Analyse dieser Website, eine Erfolgsmessung unserer
                Online-Marketing-Maßnahmen sowie Testverfahren ermöglichen, um z.B. unterschiedliche Versionen unseres Online-Angebotes oder
                seiner Bestandteile zu testen und zu optimieren. Cookies sind kleine Textdateien, die vom Internet Browser auf dem Endgerät
                des Nutzers gespeichert werden. etracker Cookies enthalten keine Informationen, die eine Identifikation eines Nutzers
                ermöglichen.
            </p>
            <p>
                Die mit etracker erzeugten Daten werden im Auftrag des Anbieters dieser Website von etracker ausschließlich in Deutschland
                verarbeitet und gespeichert und unterliegen damit den strengen deutschen und europäischen Datenschutzgesetzen und
                -standards. etracker wurde diesbezüglich unabhängig geprüft, zertifiziert und mit dem Datenschutz-Gütesiegel
                <a href="https://www.eprivacy.eu/kunden/vergebene-siegel/firma/etracker-gmbh/">ePrivacyseal</a>
                ausgezeichnet.
            </p>
            <p>
                Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des Art. 6 Abs. 1 lit. f (berechtigtes Interesse) der
                Datenschutzgrundverordnung (DSGVO). Unser Anliegen im Sinne der DSGVO (berechtigtes Interesse) ist die Optimierung unseres
                Online-Angebotes und unseres Webauftritts. Da uns die Privatsphäre unserer Besucher wichtig ist, werden die Daten, die
                möglicherweise einen Bezug zu einer einzelnen Person zulassen, wie die IP-Adresse, Anmelde- oder Gerätekennungen,
                frühestmöglich anonymisiert oder pseudonymisiert. Eine andere Verwendung, Zusammenführung mit anderen Daten oder eine
                Weitergabe an Dritte erfolgt nicht.
            </p>
            <p>Sie können der vorbeschriebenen Datenverarbeitung jederzeit widersprechen. Der Widerspruch hat keine nachteiligen Folgen.</p>
            <div class="d-flex align-items-center my-5">
                <label class="switch">
                    <input type="checkbox" [checked]="etracker?.isTrackingEnabled()" (change)="toggleSwitch()" />
                    <span class="slider round"></span>
                </label>
                <span class="ms-3"><b>Meine Besuchsdaten fließen in die Web-Analyse ein</b> </span>
            </div>
            <p>
                Weitere Informationen zum Datenschutz bei etracker finden Sie
                <a href="https://www.etracker.com/datenschutz/">hier</a>.
            </p>
        </div>
    </div>
</div>
