import { Component, Input, OnInit } from '@angular/core';
import { ImageField, KeyTextField, RichTextField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-features-slice',
    templateUrl: './features-slice.component.html',
    styleUrls: ['./features-slice.component.scss'],
    standalone: true,
    imports: [RenderHtmlPipe]
})
export class FeaturesSliceComponent implements OnInit {
    @Input() body!: FeaturesSlice;

    selectedFeature?: Feature;

    constructor() {}

    ngOnInit(): void {
        this.selectedFeature = this.body.items[0];
    }

    htmlSerializer: HTMLMapSerializer = {
        heading2: ({ children }) => '<h2 class="color-white">' + children + '</h2>'
    };

    selectFeature(feature: Feature): void {
        this.selectedFeature = feature;
    }
}

export type FeaturesSlice = Slice<
    'features',
    {
        title: TitleField;
    },
    Feature
>;

export type Feature = {
    name: KeyTextField;
    description: RichTextField;
    image: ImageField;
};
