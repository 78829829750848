import { Component, Input, OnInit } from '@angular/core';
import { LinkField, SelectField, Slice, TitleField } from '@prismicio/client';
import { LinkComponent } from '../../link/link.component';

@Component({
    selector: 'app-actionbar-slice',
    templateUrl: './actionbar-slice.component.html',
    styleUrls: ['./actionbar-slice.component.scss'],
    standalone: true,
    imports: [LinkComponent]
})
export class ActionbarSliceComponent implements OnInit {
    @Input() body!: ActionbarSlice;

    constructor() {}

    ngOnInit(): void {}
}

export type ActionbarSlice = Slice<'actionbar', never, Action>;

export type Action = {
    button_style: SelectField;
    button_title: TitleField;
    button_link: LinkField;
};
