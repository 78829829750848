import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BooleanField, FilledLinkToMediaField, ImageField, RichTextField, Slice } from '@prismicio/client';
import { AosDirective } from '../../aos/aos.directive';
import { LinkComponent } from '../../link/link.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-partner-companies-slice',
    templateUrl: './partner-companies-slice.component.html',
    styleUrls: ['./partner-companies-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, LinkComponent, RenderHtmlPipe, TranslateModule]
})
export class PartnerCompaniesSliceComponent implements OnInit {
    @Input() body!: PartnerCompaniesSlice;
    expandItems: boolean = false;

    constructor() {}

    ngOnInit(): void {}

    showAll() {
        this.expandItems = !this.expandItems;
    }
}

export type PartnerCompaniesSlice = Slice<'partnerunternehmen', { hide_items: BooleanField }, PartnerCompany>;

export type PartnerCompany = {
    logo: ImageField;
    description: RichTextField;
    link: FilledLinkToMediaField;
};
