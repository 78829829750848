import { Injectable } from '@angular/core';
import { BooleanField, KeyTextField, PrismicDocument, SliceZone } from '@prismicio/client';
import { from, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HeaderSlice } from '../shared/prismic/header-slice/header-slice.component';
import { RatingsSlice } from '../shared/prismic/ratings-slice/ratings-slice.component';

const STANDARD_PAGE = 'standard-page';

@Injectable({
    providedIn: 'root'
})
export class StandardService {
    constructor() {}

    getStandardPage(uid = 'startseite', locale: string): Observable<PrismicDocument<StandardPage>> {
        return from(
            environment.prismic.client.getByUID<PrismicDocument<StandardPage>>(STANDARD_PAGE, uid, {
                lang: locale,
                fetchLinks: [
                    'job.id',
                    'job.organization',
                    'job.title',
                    'job.short_description',
                    'job.date',
                    'job.street',
                    'job.postal_code',
                    'job.city',
                    'job.state',
                    'job.country',
                    'job.highlight',
                    'job.tags',
                    'job.video',
                    'job.thumbnail'
                ]
            })
        );
    }
}

export type StandardPage = {
    page_title: KeyTextField;
    page_description: KeyTextField;
    job_id: KeyTextField;
    is_navbar_pink: BooleanField;
    noindex: BooleanField;
    body?: SliceZone<HeaderSlice | RatingsSlice>;
};
