<header class="background-pink">
    <div class="container">
        <div class="row pt-3">
            <div class="col-12 d-flex justify-content-end">
                <img src="./assets/images/shape.svg" alt="Plus Icon" class="icon-big" />
            </div>
            <div class="col-12 pb-5 pb-lg-6 mt-5 mt-lg-0">
                <div [innerHTML]="body.primary.headline | renderHtml"></div>
                @if (body.primary.title) {
                <p class="text-size-m color-white mb-5">
                    {{ body.primary.title }}
                </p>
                } @if (stars) {
                <p>
                    <span class="text-size-m color-white mb-5"
                        >{{ stars | number : '1.1-1' }} <rating [(ngModel)]="stars" [max]="5" [readonly]="true" class="mx-3"></rating
                    ></span>
                    <span>{{ numberOfRatings }} Bewertungen</span>
                </p>
                } @if (body.primary.description) {
                <div [innerHTML]="body.primary.description | renderHtml : htmlSerializer"></div>
                }

                <div class="row">
                    @for (action of body.items; track action) {
                    <div class="col-auto">
                        <app-link
                            [link]="action.button_link"
                            [action]="action.button_action"
                            [anker]="action.button_anker"
                            [cssClass]="action.button_style === 'Secondary' ? 'btn btn-black-border mt-4' : 'btn btn-black mt-4'"
                        >
                            {{ action.button_title }}
                        </app-link>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
</header>
