import { Component, Input, OnInit } from '@angular/core';
import { BooleanField, ImageField, KeyTextField, RichTextField, Slice } from '@prismicio/client';
import { AosDirective } from '../../aos/aos.directive';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-steps-slice',
    templateUrl: './steps-slice.component.html',
    styleUrls: ['./steps-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, RenderHtmlPipe]
})
export class StepsSliceComponent implements OnInit {
    @Input() body!: StepsSlice;

    constructor() {}

    ngOnInit(): void {}
}

export type StepsSlice = Slice<
    'steps',
    {
        image: ImageField;
        image_right: BooleanField;
    },
    Steps
>;

export type Steps = {
    step_number: KeyTextField;
    title: KeyTextField;
    description: RichTextField;
};
