<section class="mb-lg-5 overflow-hidden" appAos>
    <div class="container scroller" #scroller data-speed="fast">
        <div class="row mb-5 mt-4 mt-lg-6">
            <div class="col text-center">
                <p class="heading color-grey-medium mb-0">{{ 'TRUST_BANNER_SLICE.HEADLINE' | translate }}</p>
            </div>
        </div>
        <div class="row tag-list scroller-inner">
            @for (trustElement of globalContent?.logos; track trustElement; let i = $index) {
            <div class="col-3 col-lg-2 gx-md-6 d-flex justify-content-center logo-item">
                <img [src]="trustElement?.logo?.url" [alt]="trustElement?.logo?.alt" [title]="trustElement?.logo?.alt" />
            </div>
            }
        </div>
        <div class="row mt-4">
            <div class="col">
                <hr />
            </div>
        </div>
    </div>
</section>
