<div class="container py-4 py-lg-6">
    <div class="row">
        @for (furtherPage of body.items; track furtherPage) {
        <div class="col-md-6 col-xl-4 mb-5">
            <div class="shadow p-5 text-center d-flex flex-column justify-content-between h-100">
                <h3>{{ furtherPage.title }}</h3>
                <img [src]="furtherPage.image.url" [alt]="furtherPage.image.alt" class="w-100 my-5" />
                <app-link [link]="furtherPage.button_link" [cssClass]="'btn btn-black'">
                    {{ furtherPage.button_title }}
                </app-link>
            </div>
        </div>
        }
    </div>
</div>
