import { Component, Input, OnInit } from '@angular/core';
import { KeyTextField, RichTextField, SelectField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { AosDirective } from '../../aos/aos.directive';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-process-slice',
    templateUrl: './process-slice.component.html',
    styleUrls: ['./process-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, RenderHtmlPipe]
})
export class ProcessSliceComponent implements OnInit {
    @Input() body!: ProcessSlice;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        heading2: ({ children }) => '<h2 class="text-size-xl color-white">' + children + '</h2>',
        heading3: ({ children }) => '<h3 class=" color-white">' + children + '</h3>'
    };
}

export type ProcessSlice = Slice<
    'prozess',
    {
        heading: KeyTextField;
        title: TitleField;
    },
    ProcessStep
>;

export type ProcessStep = {
    title: TitleField;
    description: RichTextField;
    offset: SelectField;
};
