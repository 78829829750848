import { Component, Input, OnInit } from '@angular/core';
import { BooleanField, ImageField, RichTextField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { AosDirective } from '../../aos/aos.directive';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-list-slice',
    templateUrl: './list-slice.component.html',
    styleUrls: ['./list-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, RenderHtmlPipe]
})
export class ListSliceComponent implements OnInit {
    @Input() body!: ListSlice;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        paragraph: ({ children }) => `<p class="mb-0">${children}</p>`
    };
}

export type ListSlice = Slice<
    'list',
    {
        description: RichTextField;
        list_position_right: BooleanField;
    },
    ListItem
>;

export type ListItem = {
    image: ImageField;
    title: TitleField;
    description: RichTextField;
};
