<div class="background-pink mt-5 mt-lg-6 pt-5 pt-lg-6">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-8 text-center">
                <div [innerHTML]="body.primary.title | renderHtml : htmlSerializer"></div>
            </div>
        </div>
        <div class="row d-flex justify-content-center mt-5">
            @for (feature of body.items; track feature) {
            <div class="col-lg-auto text-center">
                <button class="btn color-white" [class.btn-white-border]="feature === selectedFeature" (click)="selectFeature(feature)">
                    {{ feature.name }}
                </button>
            </div>
            }
        </div>
        <div class="row d-flex justify-content-center my-5">
            <div class="col-lg-8 text-center">
                <div [innerHTML]="selectedFeature?.description | renderHtml"></div>
            </div>
        </div>
    </div>
</div>
<div class="container image pb-5 pb-lg-6">
    <div class="row">
        <div class="col-12">
            <img [src]="selectedFeature?.image.url" [alt]="selectedFeature?.image.alt" class="w-100 shadow-lg" />
        </div>
    </div>
</div>
