import { Component, ViewEncapsulation } from '@angular/core';

const DOMAIN = 'gotoitcareer.com';

@Component({
    selector: 'app-privacy-etracker',
    templateUrl: './privacy-etracker.component.html',
    styleUrls: ['./privacy-etracker.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class PrivacyEtrackerComponent {
    etracker?: Etracker;

    constructor() {
        this.etracker = (window as { [key: string]: any })['_etracker'] as Etracker;
    }

    toggleSwitch() {
        this.etracker?.isTrackingEnabled() ? this.etracker?.disableTracking(DOMAIN) : this.etracker?.enableTracking(DOMAIN);
    }
}
