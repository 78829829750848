import { Component, Input, OnInit } from '@angular/core';
import { ImageField, KeyTextField, LinkField, Slice } from '@prismicio/client';
import { LinkComponent } from '../../link/link.component';

@Component({
    selector: 'app-further-pages-slice',
    templateUrl: './further-pages-slice.component.html',
    styleUrls: ['./further-pages-slice.component.scss'],
    standalone: true,
    imports: [LinkComponent]
})
export class FurtherPagesSliceComponent implements OnInit {
    @Input() body!: FurtherPagesSlice;

    constructor() {}

    ngOnInit(): void {}
}

export type FurtherPagesSlice = Slice<'further_pages', never, FurtherPage>;

export type FurtherPage = {
    image: ImageField;
    title: KeyTextField;
    button_title: KeyTextField;
    button_link: LinkField;
};
