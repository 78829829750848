import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DateField, KeyTextField, RichTextField, SelectField, Slice } from '@prismicio/client';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-release-notes-slice',
    templateUrl: './release-notes-slice.component.html',
    styleUrls: ['./release-notes-slice.component.scss'],
    standalone: true,
    imports: [DatePipe, RenderHtmlPipe]
})
export class ReleaseNotesSliceComponent {
    @Input() body!: ReleaseNotesSlice;

    constructor() {}

    ngOnInit(): void {}
}

export type ReleaseNotesSlice = Slice<
    'release_notes',
    {
        version: KeyTextField;
        date: DateField;
    },
    ReleaseNote
>;

export type ReleaseNote = {
    type: SelectField;
    description: RichTextField;
};
