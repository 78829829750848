<div class="container mb-5">
    <div class="row">
        <div class="col-12">
            <p class="mb-0">
                <span class="text-size-m">{{ body.primary.version }}</span>
                <span class="color-grey ms-3">(Veröffentlicht am {{ body.primary.date | date }})</span>
            </p>
        </div>
    </div>
    @for (releaseNote of body.items; track releaseNote) {
    <div class="row mt-3">
        <div class="col-12">
            <p class="tag mb-3">{{ releaseNote.type }}</p>
        </div>
        <div class="col-12">
            <div [innerHTML]="releaseNote.description | renderHtml"></div>
        </div>
    </div>
    }
</div>
