<header>
    <div class="container">
        <div class="row pt-3 pt-lg-5">
            <div class="col-12 d-flex justify-content-end">
                <img src="../../../../../assets/images/plus-white.svg" alt="Plus Icon" class="icon-big icon-animated" />
            </div>
            <div class="col-12 content position-relative" appAos #headerContainer>
                <div [innerHTML]="body.primary.slogan | renderHtml : htmlSerializer"></div>
                <div [innerHTML]="body.primary.headline | renderHtml : htmlSerializer"></div>
                @defer (on viewport(headerContainer)) {
                <div class="col-lg-7 image">
                    <img [src]="body.primary.image.url" [alt]="body.primary.image.alt" class="w-100 image-mask mask-03" loading="eager" />
                </div>
                }
            </div>
            <div class="col-12 content pb-5" appAos>
                <div [innerHTML]="body.primary.title | renderHtml : htmlSerializer"></div>
                <div class="row">
                    <div class="col-lg-8 col-xxl-6">
                        <hr class="line mb-5" />
                        <div [innerHTML]="body.primary.description | renderHtml"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="container application-form-wrapper">
    <div class="row">
        <div class="col-12 position-relative">
            <app-application-form [horizontalMode]="true"></app-application-form>
        </div>
    </div>
</div>
