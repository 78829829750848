import { Component, Input, OnInit } from '@angular/core';
import { ImageField, RichTextField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { AosDirective } from '../../aos/aos.directive';
import { ApplicationFormComponent } from '../../application-form/application-form.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-header-slice',
    templateUrl: './header-slice.component.html',
    styleUrls: ['./header-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, ApplicationFormComponent, RenderHtmlPipe]
})
export class HeaderSliceComponent implements OnInit {
    @Input() body!: HeaderSlice | any;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        paragraph: ({ children }) => '<p class="text-size-xxl">' + children + '</p>',
        heading1: ({ children }) => '<h1 class="text-size-m color-white mb-0">' + children + '</h1>',
        heading2: ({ children }) => '<h2 class="text-size-xl color-white my-lg-6">' + children + '</h2>'
    };
}

export type HeaderSlice = Slice<
    'header',
    {
        slogan: RichTextField;
        headline: TitleField;
        title: TitleField;
        description: RichTextField;
        image: ImageField;
    },
    never
>;
