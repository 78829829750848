<div class="container">
    <div class="row py-5 py-lg-6">
        <div class="col-lg-4 px-5 px-lg-0 d-flex align-items-center" [class.order-lg-1]="body.primary.image_right" appAos>
            <img [attr.data-src]="body.primary.image.url" [alt]="body.primary.image.alt" class="w-100 lazyload" />
        </div>
        <div
            class="col-lg-8 mt-4 mt-lg-0"
            [class.pe-lg-6]="body.primary.image_right"
            [class.ps-lg-6]="!body.primary.image_right"
            [class.order-lg-0]="body.primary.image_right"
        >
            @for (step of body.items; track step) {
            <div class="row" appAos>
                <div class="col-12">
                    <div class="mt-5">
                        <div class="row">
                            <div class="col-auto pe-4">
                                <p class="color-pink text-size-xl">{{ step.step_number }}</p>
                            </div>
                            <div class="col">
                                <p class="text-size-m mb-2">{{ step.title }}</p>
                                <div [innerHTML]="step.description | renderHtml"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
</div>
