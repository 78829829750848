import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ContentRelationshipField, Slice } from '@prismicio/client';
import { Job } from '../../../../services/jobs.service';
import { JobItemComponent } from '../jobs-slice/components/job-item/job-item.component';

@Component({
    selector: 'app-jobs-manual-slice',
    standalone: true,
    imports: [JobItemComponent, TranslateModule],
    templateUrl: './jobs-manual-slice.component.html',
    styleUrls: ['./jobs-manual-slice.component.scss']
})
export class JobsManualSliceComponent {
    @Input() body?: JobsManualSlice;
}

export type JobsManualSlice = Slice<
    'jobs_manual',
    never,
    {
        job: ContentRelationshipField<Job>;
    }
>;
