<div class="background-pink">
    <div class="container py-4 py-lg-6">
        <div class="row">
            <div class="col-lg-2 order-lg-1 d-flex justify-content-end pt-5">
                <img src="./assets/images/dots-plus-white.svg" alt="Design-Element" class="icon-big shape" appAos />
            </div>
            <div class="col-lg-10 pe-4 order-lg-0" appAos>
                <p class="heading">{{ body.primary.heading }}</p>
                <div [innerHTML]="body.primary.title | renderHtml : htmlSerializer"></div>
            </div>
        </div>
        <div class="arrow mt-5 mt-lg-6">
            @for (processStep of body.items; track processStep) {
            <div class="row mt-5 mt-xl-5" appAos>
                <div class="col-xl-3" [class]="processStep.offset">
                    <div [innerHTML]="processStep.title | renderHtml : htmlSerializer"></div>
                    <div [innerHTML]="processStep.description | renderHtml"></div>
                </div>
            </div>
            }
        </div>
    </div>
</div>
